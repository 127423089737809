import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'presence.index', params: { world: world.id } },
    module: 'presence',
    permission: {
        'module': 'presence',
        'name': 'view_module',
    },
    title: 'modules.presence.title',
    weight: 40,
    group: 'people',
    description: 'modules.presence.module_desc',
  },
]

export default links
