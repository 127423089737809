import _ from 'lodash'
import moment from 'moment'
import store from '@/store'


const NO_GROUP = '_'
const GROUP_BOTTOM = 'GROUP_BOTTOM'

export default {
  data () {
    return {
      NO_GROUP: NO_GROUP,
      GROUP_BOTTOM: GROUP_BOTTOM,
      groupConfig: {
        [NO_GROUP]: {
          title: '',
          icon: '',
          weight: 0,
        },
        'actions': {
          title: this.$t('groups.actions'),
          icon: 'stack-check',
          weight: 5,
        },
        'people': {
          title: this.$t('groups.people'),
          icon: 'people',
          weight: 10,
        },
        'handbook': {
          title: this.$t('groups.handbook'),
          icon: 'bookmark',
          weight: 20,
        },
        'organisation': {
          title: this.$t('groups.organisation'),
          icon: 'office',
          weight: 30,
        },
        'marketplace': {
          title: this.$t('groups.marketplace'),
          icon: 'store',
          weight: 40,
        },
        'strategy': {
          title: this.$t('groups.strategy'),
          icon: 'target',
          weight: 50,
        },
        'admin': {
          title: this.$t('groups.admin'),
          icon: 'lock2',
          weight: 60,
        },
        [GROUP_BOTTOM]: {
          title: '',
          icon: '',
          weight: 99,
        },
      },
    }
  },
  methods: {
    ifLinkNew (link) {
      if (!this.isAuth() || !link.module || !this.modules._data || !this.modules._data[link.module] || !this.modules._data[link.module].activated_at) {
        return false
      }
      let activatedAt = this.modules._data[link.module].activated_at
      return moment(activatedAt).isSame(moment(), 'day')
    }
  },
  computed: {
    activeGroup () {
      let activeGroup = null
      let activeRoute = this.$route
      _.forEach(_.cloneDeep(this.menuLinksFormatted), (groupData, groupKey) => {
        _.forEach(groupData.links, (link) => {
          if (link.to.name != 'module.index' && link.to.name == activeRoute.name) {
            activeGroup = groupKey
            return false
          }
          else if (link.to.name == 'module.index' && link.to.name == activeRoute.name && link.to.params.module == activeRoute.params.module && link.to.params.page == activeRoute.params.page) {
            activeGroup = groupKey
            return false
          }
        })
      })
      return activeGroup
    },
    moduleClassicLinks () {
      let links = []
      if (!this.modules || !this.world) {
        return links
      }

      _.forEach(this.modules, (moduleData, moduleKey) => {
        if (
          moduleData.pages &&
          Object.keys(moduleData.pages).length > 0 &&
          this.userHasPermission(moduleKey, 'view_module')
        ) {
          _.forEach(moduleData.pages, (pageData, pageKey) => {

            if (pageData.super_admin_only) {
              let roleCondition = (this.user && this.user.roles_slugs && this.user.roles_slugs.includes('admin')) ? true : false;
              if (!roleCondition) {
                return // tell lodash to go to next iteration on forEach
              }
            }

            let image = null
            try {
              image = require('../../assets/images/modules/' + moduleKey + '.svg')
            } catch(e) {}

            links.push({
              to: {
                name: pageData.link ? pageData.link : 'module.index',
                params: { world: this.world.id, module: moduleKey, page: pageKey }
              },
              title: this.$t(`modules.${moduleKey}.${pageKey}.title`),
              weight: pageData.weight ? pageData.weight : 999,
              icon: pageData.icon ? pageData.icon : 'menu',
              image: image,
              group: pageData.group ? pageData.group : this.NO_GROUP,
              description: this.$t(`modules.${moduleKey}.${pageKey}.module_desc`),
              module: moduleKey,
              active: moduleData.active,
            })
          })
        }
      })
      return links
    },
    moduleStandaloneLinks () {
        // Module routes
        let requireSidebarLinks = require.context(
          // The relative path of the components folder
          '../../../Modules',
          // Whether or not to look in subfolders
          true,
          // The regular expression used to match base component filenames
          /sidebar-links.js$/
        )

        let moduleStandaloneLinks = []
        requireSidebarLinks.keys().forEach(fileName => {
          // Get module routes
          let linksConfig = requireSidebarLinks(fileName)
          moduleStandaloneLinks = moduleStandaloneLinks.concat(linksConfig.default)
        })
        let refreshWorld = _.map(moduleStandaloneLinks, link => {
            if (link.to && link.to.params && link.to.params.world)
            link.to.params.world = this.world.id

            return link
        })
        return refreshWorld
    },
    menuLinks () {
    let links = []
      // Render fake links
      if (this.isAuthFake) {
          links = [
            {
              group: GROUP_BOTTOM,
              module: 'core',
              to: { name: 'attractions.index', params: { world: this.world.id } },
              title: 'common.attractions_all',
              weight: 99,
              active: true,
            },
            {
              module: 'core',
              to: {name: 'world.users', params: { world: this.world.id }},
              condition: (this.world) ? true : false,
              title: 'titles.world_users',
              weight: 10,
              group: 'people',
              image: require('../../assets/images/users.svg'),
              description: 'user.list.module_desc',
              active: true,
            },
            {
              module: 'core',
              to: {name: 'world.tribes', params: { world: this.world.id }},
              condition: (this.world) ? true : false,
              title: 'titles.world_tribes',
              weight: 11,
              group: 'people',
              image: require('../../assets/images/tribes.svg'),
              description: 'tribe.list.module_desc',
              active: true,
            },
          ]

          if (process.env.VUE_APP_TITLE == 'Magic Office') {
              links.push({
                module: 'actionsboard',
                to: { name: 'actionsboard.index', params: { world: this.world.id } },
                title: 'groups.actions',
                weight: 12,
                active: true,
              })
              links.push({
                module: 'templates',
                to: { name: 'templates.index', params: { world: this.world.id } },
                title: 'modules.templates.title',
                weight: 15,
                active: true,
              })
              links.push({
                module: 'process',
                to: { name: 'process.template.index', params: { world: this.world.id } },
                title: 'modules.process.process.templates.title',
                weight: 16,
                active: true,
              })
          }
      } else {
          links = [
            {
                module: 'core',
                to: { name: 'attractions.index', params: { world: this.world.id } },
                condition: (this.world && this.modules) ? true : false,
                title: 'common.attractions',
                weight: 0,
                active: true,
            },
            {
                module: 'core',
                to: {name: 'world.users', params: { world: this.world.id }},
                condition: (this.world) ? true : false,
                title: 'titles.world_users',
                weight: 10,
                group: 'people',
                image: require('../../assets/images/users.svg'),
                description: 'user.list.module_desc',
                active: true,
            },
            {
                module: 'core',
                to: {name: 'world.tribes', params: { world: this.world.id }},
                condition: (this.world) ? true : false,
                title: 'titles.world_tribes',
                weight: 11,
                group: 'people',
                image: require('../../assets/images/tribes.svg'),
                description: 'tribe.list.module_desc',
                active: true,
            },
          ]
          links = links.concat(_.cloneDeep(this.moduleStandaloneLinks)).concat(_.cloneDeep(this.moduleClassicLinks))
      }

      // links = _.filter(links, l => {
      //   return l.module == "core" || this.world.modules[l.module]
      // })
      return links
    },
    menuLinksFormatted () {
      let links = _.map(_.cloneDeep(this.menuLinks), link => {
        if (!link.hasOwnProperty('translate_title') || link.translate_title == true) {
          link.title = this.$t(link.title)
        }
        if ((!link.hasOwnProperty('translate_description') || link.translate_description == true) && link.description) {
          link.description = this.$t(link.description)
        }
        if (!link.hasOwnProperty('image')) {
          link.image = null
        }
        if (!link.hasOwnProperty('condition')) {
          link.condition = true
        }
        if (!link.hasOwnProperty('owner_only')) {
          link.owner_only = false
        }
        if (!link.hasOwnProperty('permission')) {
          link.permission = null
        }
        if (!link.hasOwnProperty('module')) {
          link.module = null
        }
        if (!link.hasOwnProperty('description')) {
          link.description = null
        }
        if (!link.hasOwnProperty('group')) {
          link.group = this.NO_GROUP
        }
        if (!link.hasOwnProperty('icon')) {
          link.icon = this.$router.resolve(link.to).route.meta.icon
        }
        if (!link.hasOwnProperty('active') && link.hasOwnProperty('module')) {
            link.active = link.module && this.world.modules && this.world.modules[link.module]
        } else if (!link.hasOwnProperty('active')) {
            link.active = false
        }
        if (link.module && this.modules && this.modules[link.module] && this.modules[link.module].show_permissions == true) {
            link.permissions = 'custom'
        }

        return link
      })

      if (!this.isAuthFake) {
          // Filter links by permission
          links = links.filter((link) => {
            let keepLink = true
            if (
                !this.modules ||
                (!this.modules[link.module] && link.module !== 'core') ||
                link.condition != true ||
                (link.owner_only == true && !this.isCurrentWorldOwner) ||
                (link.permission && !this.userHasPermission(link.permission.module, link.permission.name))
            ) {
              keepLink = false
            }
            return keepLink
          })
      }

      let groupedLinks = {}
      _.forEach(links, link => {
        if (!groupedLinks[link.group]) {
          groupedLinks[link.group] = {
            key: link.group,
            weight: this.groupConfig[link.group].weight,
            links: [],
          }
        }
        groupedLinks[link.group].links.push(link)
      })

      _.map(groupedLinks, group => {
        group.links = _.sortBy(group.links, ['weight', 'title'])
        return group
      })

      groupedLinks = _.sortBy(groupedLinks, ['weight', 'title'])
      groupedLinks = _.keyBy(groupedLinks, 'key')

      return groupedLinks
    },
    menuLinksFormattedFlat() {
        let links = []
        _.forEach(_.cloneDeep(this.menuLinksFormatted), group => {
            _.forEach(group.links, link => {
                links.push(link)
            })
        })
        return links
    },
    menuLinksFormattedActive () {
        let menuLinks = _.cloneDeep(this.menuLinksFormatted)
        if (this.isAuthFake) {
            return menuLinks
        }

        _.forEach(menuLinks, (groupLink, groupKey) => {
            menuLinks[groupKey].links = _.filter(menuLinks[groupKey].links, (link) => {
                return link.active
            })
            if (menuLinks[groupKey].links.length == 0) {
                delete menuLinks[groupKey]
            }
        })

        return menuLinks
    },
    modules () {
      return store.getters['world/getModules'];
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    user () {
      return store.getters['auth/getProfile'];
    },
    world () {
      return store.getters['auth/getWorld'];
    },
  },
  watch: {
      modules: {
          handler () {
              this.$forceUpdate();
          },
          deep: true,
      },
      user: {
          handler () {
              this.$forceUpdate();
          },
          deep: true,
      },
      world: {
          handler () {
              this.$forceUpdate();
          },
          deep: true,
      },
  },
}
